import gql from "graphql-tag";

export const TEAMS = gql`
  query Teams {
    teams {
      id
      name
      color
    }
  }
`;
